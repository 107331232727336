import React from "react";
import { PATHS } from "./paths";
import Layout from "pages/Layout/Layout";

const Home = React.lazy(() => import("pages/Home"));
const Showcase = React.lazy(() => import("pages/Showcase"));
const Features = React.lazy(() => import("pages/FeaturesPage"));
const About = React.lazy(() => import("pages/About"));
const Ambassadors = React.lazy(() => import("pages/Ambassadors"));
const PrivacyPolicy = React.lazy(() => import("pages/PrivacyPolicy"));
const TermsOfUse = React.lazy(() => import("pages/TermsOfUse"));
const NewPassword = React.lazy(() => import("pages/NewPassword"));
const Pricing = React.lazy(() => import("pages/Pricing"));
const Account = React.lazy(() => import("pages/Account"));
const Create = React.lazy(() => import("pages/Create"));
const EmailVerified = React.lazy(() => import("pages/EmailVerified"));
const AIPhotoshoot = React.lazy(() => import("pages/AIPhotoshoot"));

export interface RouteConfig {
  path: string;
  component: React.LazyExoticComponent<() => JSX.Element>;
  layout?: React.ElementType;
  layoutProps?: {
    hiddenFooter: boolean;
    hiddenHeader: boolean;
  };
  needLogin?: boolean;
  requireLogin?: boolean;
  children?: RouteConfig[];
  isResponsive?: boolean;
  mobileComponent?: React.LazyExoticComponent<() => JSX.Element>;
}

export const ROUTES: RouteConfig[] = [
  {
    path: PATHS.HOME,
    component: Home,
    needLogin: false,
    layout: Layout,
  },
  {
    path: PATHS.SHOWCASE,
    component: Showcase,
    needLogin: false,
    layout: Layout,
  },
  {
    path: PATHS.FEATURES,
    component: Features,
    needLogin: false,
    layout: Layout,
  },
  // {
  //   path: PATHS.AI_PHOTOSHOOT,
  //   component: AIPhotoshoot,
  //   needLogin: false,
  //   layout: Layout,
  // },
  {
    path: PATHS.ABOUT,
    component: About,
    needLogin: false,
    layout: Layout,
  },
  {
    path: PATHS.AMBASSADORS,
    component: Ambassadors,
    needLogin: false,
    layout: Layout,
  },
  {
    path: PATHS.LOGIN,
    component: Home,
    needLogin: true,
    layout: Layout,
  },
  {
    path: PATHS.NEW_PASSWORD,
    component: NewPassword,
    needLogin: false,
    layout: Layout,
    layoutProps: { hiddenFooter: true, hiddenHeader: true },
  },
  {
    path: PATHS.PRICING,
    component: Pricing,
    needLogin: false,
    layout: Layout,
  },
  // {
  //   path: PATHS.ACCOUNT,
  //   component: Account,
  //   needLogin: true,
  //   layout: Layout,
  // },
  // {
  //   path: PATHS.CREATE,
  //   component: Create,
  //   needLogin: false,
  //   layout: Layout,
  // },
  {
    path: PATHS.PRIVACY_POLICY,
    component: PrivacyPolicy,
    needLogin: false,
    layout: Layout,
  },
  {
    path: PATHS.TERMS_OF_USE,
    component: TermsOfUse,
    needLogin: false,
    layout: Layout,
  },
  {
    path: PATHS.EMAIL_VERIFY,
    component: EmailVerified,
    needLogin: true,
    layout: Layout,
  },
];
