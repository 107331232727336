import React, { useEffect, useRef } from "react";

const LazyImg = ({ src, ...etc }: any) => {
  const ref = useRef<any>();
  useEffect(() => {
    const observer = new IntersectionObserver((entries, obs) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          ref.current.src = src;
          obs.disconnect();
        }
      }
    }, {});
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return <img {...etc} src={""} ref={ref} />;
};

export default LazyImg;
