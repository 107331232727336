import React, { useRef, useState } from "react";
import "./Header.css";
import { IconChevron, IconMenu, IconPlay, LogoRefabric } from "icons";
import Button from "components/Button/Button";
import clsx from "clsx";
import AvatarMenu from "components/AvatarMenu/AvatarMenu";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser, toggleAnnouncementModal } from "store/commonSlice";
import { useAppSelector } from "store";
import userService from "api/user/user.service";
import { useIsMobile } from "hooks/useIsMobile";
import { AnalyticsEvent } from "utils";
import { PATHS } from "../../../router/config/paths";
import { APP_URL, BASIC_APP_CREATE_URL, CLIENT_ID_LOCAL_STORAGE_KEY } from "utils/constants";
import { getAuthTokenFromLocalStorage } from "hooks/useAuthToken";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useClickOutside } from "hooks/useClickOutside";
import MobileHeaderMenu from "./MobileHeaderMenu";
import AnnouncementModal from "./AnnouncementModal";
import { FeatureItems } from "pages/FeaturesPage/components/DesignTools";
import { ImageHeader1, ImageHeader2 } from "images";
import BeforeAfterImage from "pages/FeaturesPage/components/BeforeAfterImage";
import { PricingType } from "pages/Pricing/Pricing";
import PremiereVisionModal from "components/PremiereVisionModal";

export enum PageType {
  Explore = "/",
  Create = "/create",
  Pricing = "/pricing",
}

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = useState(false);
  const [initTab, setInitTab] = React.useState<any>(location.pathname);
  const { isAuthorized, user, isCheckingToken } = useAppSelector((state) => state.common);
  const [showProductsSelect, setShowProductsSelect] = useState(false);
  const productsSelectRef = useRef(null);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showPremiereVisionModal, setShowPremiereVisionModal] = useState(false);

  useClickOutside(productsSelectRef, () => setShowProductsSelect(false));

  React.useEffect(() => {
    if (location.pathname !== initTab) {
      setInitTab(location.pathname);
    }
  }, [location.pathname]);

  React.useEffect(() => {
    if (user.id !== "") {
      userService
        .getUserInfo({ uid: user?.id })
        .then((res: any) => dispatch(setUser({ id: res.user_info.id, email: res.user_info.email, name: res.user_info.name, username: res.user_info.username, work: res.user_info.work })));
    }
  }, [isAuthorized]);

  React.useEffect(() => {
    setShowPremiereVisionModal(true);
  }, []);

  const toggleDropdown = () => {
    AnalyticsEvent("profile_tapped", "", "click");
    setIsOpen(!isOpen);
  };

  const HeaderItems: any[] = [
    {
      title: "Features",
      // Icon: <IconChevron className="w-5 h-5" />,
      // renderItem: ({ setShowRenderItem }: any) => {
      //   return (
      //     <div className="flex gap-5 p-6 rounded-2xl border border-black bg-white w-[600px]">
      //       <div className="flex flex-col gap-5 w-full">
      //         <span className="text-h7-satoshi text-black">Design Features</span>
      //         {FeatureItems[6].render && FeatureItems[6].render()}
      //         <span className="text-bodyLg text-black w-[266px]">Visualize and edit your designs on hyper-realistic AI models.</span>

      //         <Button
      //           className="btn-cta w-fit"
      //           onClick={() => {
      //             AnalyticsEvent("header_features", "", "click");
      //             navigate(PATHS.FEATURES + window.location.search);
      //             setShowRenderItem(false);
      //           }}
      //         >
      //           Explore
      //         </Button>
      //       </div>
      //       <div className="flex flex-col gap-5 w-full">
      //         <span className="text-h7-satoshi text-black">AI Photoshoot</span>
      //         <div className="flex w-full max-w-[440px] rounded-md overflow-hidden aspect-[0.73]">
      //           <BeforeAfterImage sliderPercentPosition={48} mainImage={ImageHeader1} images={[ImageHeader2]} />
      //         </div>
      //         <span className="text-bodyLg text-black w-[266px]">Create pro fashion shots without altering garment details.</span>

      //         <Button
      //           className="btn-cta w-fit"
      //           onClick={() => {
      //             AnalyticsEvent("header_ai_photoshoot", "", "click");
      //             navigate(PATHS.AI_PHOTOSHOOT + window.location.search);
      //             setShowRenderItem(false);
      //           }}
      //         >
      //           Explore
      //         </Button>
      //       </div>
      //     </div>
      //   );
      // },
      onClick: () => {
        AnalyticsEvent("header_features", "", "click");
        navigate(PATHS.FEATURES + window.location.search);

        return;
      },
    },
    {
      title: "Showcase",
      onClick: () => {
        AnalyticsEvent("header_showcase", "", "click");
        navigate(PATHS.SHOWCASE + window.location.search);

        return;
      },
    },
    {
      title: "Pricing",
      onClick: () => {
        AnalyticsEvent("header_pricing", "", "click");
        navigate(PATHS.PRICING + window.location.search);

        return;
      },
    },
    {
      title: "Ambassadors",
      onClick: () => {
        AnalyticsEvent("header_ambassadors", "", "click");
        navigate(PATHS.AMBASSADORS + window.location.search);

        return;
      },
    },
    {
      title: "How To Use",
      onClick: () => {
        AnalyticsEvent("header_how_to_use", "", "click");
        window.open("https://explorefeatures.refabric.com/", "_blank");

        return;
      },
    },
    {
      title: "About",
      onClick: () => {
        AnalyticsEvent("header_about", "", "click");
        navigate(PATHS.ABOUT + window.location.search);

        return;
      },
    },
  ];

  const renderLinks = () => {
    return (
      !isMobile && (
        <div className="flex gap-8 text-[17px] font-normal leading-normal text-grey">
          {HeaderItems.map((item, index) => {
            const [showRenderItem, setShowRenderItem] = React.useState(false);
            const containerRef = useRef(null);

            useClickOutside(containerRef, () => {
              setShowRenderItem(false);
            });

            return (
              <div className="relative" key={`header-button_${index}`}>
                <a
                  className={clsx("header-button", showProductsSelect || showRenderItem ? "pointer-events-none" : "")}
                  onClick={() => {
                    if (item.renderItem) {
                      setShowRenderItem(true);
                    } else {
                      item.onClick && item.onClick();
                    }
                  }}
                >
                  {item.title}
                  {item.Icon}
                </a>

                {item.renderItem && showRenderItem && (
                  <div className="absolute top-10 left-0" ref={containerRef}>
                    {item.renderItem({ setShowRenderItem })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )
    );
  };

  const renderCreateButton = () => {
    return (
      !isMobile &&
      location.pathname !== PageType.Create && (
        <Button
          className="btn-primary"
          onClick={() => {
            AnalyticsEvent("create_button_header", "", "click");
            navigate(BASIC_APP_CREATE_URL + "?token=" + getAuthTokenFromLocalStorage() + "&client_id=" + useLocalStorage().getItem(CLIENT_ID_LOCAL_STORAGE_KEY));
          }}
        >
          Create
        </Button>
      )
    );
  };

  const Announcement = () => (
    <div
      className="cursor-pointer flex-center w-full p-2.5 gap-2.5 bg-green"
      onClick={() => {
        AnalyticsEvent("header_announcement", "", "click");
        dispatch(toggleAnnouncementModal());
      }}
    >
      <span className="text-h7-satoshi text-center !text-[15px]">Refabric is now part of the LVMH Startup Acceleration Program at La Maison des Startups</span>
      <IconPlay className=" w-[14px] h-[14px] lg:w-4 lg:h-4 flex-shrink-0" />
    </div>
  );

  return (
    <div className={clsx("flex items-center sticky top-0 z-50 w-full flex-col  border-b border-grey-light")}>
      <Announcement />
      <AnnouncementModal />
      <PremiereVisionModal
        show={showPremiereVisionModal}
        onClose={() => {
          setShowPremiereVisionModal(false);
        }}
      />

      {isMobile && showMobileMenu && <MobileHeaderMenu show={showMobileMenu} headerItems={HeaderItems} onClose={() => setShowMobileMenu(false)} />}

      <div className="container-fluid-header flex h-full gap-7 items-center bg-white w-full justify-between py-3 lg:px-5 border-b border-grey-light lg:border-0">
        <div className="flex items-center gap-10">
          <LogoRefabric
            className="cursor-pointer"
            onClick={() => {
              AnalyticsEvent("header_refabric_logo", "", "click");
              navigate(PATHS.HOME + window.location.search);
            }}
          />
          {renderLinks()}
        </div>
        <div className="flex justify-end relative items-center gap-2.5 w-full lg:max-w-[300px]">
          {isCheckingToken ? (
            <></>
          ) : !isAuthorized ? (
            <>
              {!isMobile && (
                <a
                  href={APP_URL + "/login" + window.location.search}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-full"
                  onClick={() => {
                    // AnalyticsEvent("header_signin", "", "click");
                  }}
                >
                  <Button className="btn-secondary btn-sm whitespace-nowrap w-full">Sign In</Button>
                </a>
              )}
              <Button
                className="btn-primary btn-sm whitespace-nowrap flex-shrink-0 w-full max-w-[144px]"
                onClick={() => {
                  AnalyticsEvent("header_tryforfree", "", "click");
                  navigate(PATHS.PRICING + window.location.search, { state: { type: window.location.pathname === PATHS.AI_PHOTOSHOOT ? PricingType.Photoshoot : PricingType.Design } });
                }}
              >
                Try for Free
              </Button>
              {isMobile && <IconMenu className="text-black w-6 h-6 flex-shrink-0" onClick={() => setShowMobileMenu(true)} />}
            </>
          ) : (
            <>
              {renderCreateButton()}
              {user.username !== "" && (
                <Button
                  disabled={isOpen}
                  onClick={toggleDropdown}
                  className="flex-center text-h5 text-white py-2.5 px-4 rounded-full bg-white bg-opacity-10 border border-white border-opacity-10 uppercase"
                >
                  {user?.username?.slice(0, 1)}
                </Button>
              )}
              <div className="absolute top-[56px] right-0">{isOpen && <AvatarMenu setIsOpen={setIsOpen} />}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
