import { IconChecked } from "icons";
import React from "react";

interface Props {
  data: any;
  headers: any[];
  objectKey: string;
}

const TableComponent: React.FC<Props> = ({ data, headers, objectKey }) => {
  return (
    <div className="w-full">
      <table className="w-full text-black border-separate border-spacing-4">
        <thead></thead>
        <tbody className="">
          {headers.map((header, index) => (
            <tr key={header} className="">
              <td className="relative text-bodyMd block py-2.5 whitespace-nowrap min-w-[153px]">
                {header.name}
                {header.name === "Virtual Try On" && (
                  <div className="absolute right-0 top-1/2 -translate-y-1/2">
                    <div className="uppercase rounded-[3px] py-[2px] px-[5px] text-headline bg-[#F5F4F3]">soon</div>
                  </div>
                )}
              </td>
              {data.map((item: any, idx: number) => (
                <td key={idx} className="text-bodyMd text-center text-black w-[264px] p-2.5">
                  {item[objectKey][index].value === "true" ? (
                    <div className="flex-center">
                      <IconChecked />
                    </div>
                  ) : item[objectKey][index].value === "false" ? (
                    "-"
                  ) : (
                    <>{item[objectKey][index].value}</>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
