/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useRef } from "react";

const ScrollContext = createContext({
  registerRef: (id: any, ref: any) => {},
  scrollToTarget: (id: any) => {},
});

const ScrollProvider = ({ children }: any) => {
  const refs = useRef<any>({});

  const registerRef = (id: any, ref: any) => {
    refs.current[id] = ref;
  };

  const scrollToTarget = (id: any) => {
    if (refs.current[id]) {
      const element = refs.current[id];
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - 75;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return <ScrollContext.Provider value={{ registerRef, scrollToTarget }}>{children}</ScrollContext.Provider>;
};

export default ScrollProvider;

export const useScroll = () => useContext(ScrollContext);
