import React from "react";
import { IconClose, IconRightArrow2, LogoRefabric } from "icons";
import TryForFree from "components/TryForFree";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";
import { PATHS } from "router/config/paths";
import { AnalyticsEvent } from "utils";
import { APP_URL } from "utils/constants";
import clsx from "clsx";
import { FeatureItems } from "pages/FeaturesPage/components/DesignTools";
import BeforeAfterImage from "pages/FeaturesPage/components/BeforeAfterImage";
import { ImageHeader1, ImageHeader2 } from "images";

const body = document.querySelector("body");

const MobileHeaderMenu = ({ show, headerItems, onClose }: any) => {
  const navigate = useNavigate();
  const [showFeaturesMenu, setShowFeaturesMenu] = React.useState(false);

  React.useEffect(() => {
    if (show && body) {
      body.style.overflow = "hidden";
    }

    return () => {
      if (body) body.style.overflow = "auto";
    };
  }, [show]);

  return (
    <div className="fixed h-screen w-screen bg-white z-10">
      <div className="flex flex-col fixed top-0 left-0 w-full z-20 bg-white h-full">
        <div className="flex items-center justify-between p-5 border-b border-grey-light">
          <LogoRefabric
            className="cursor-pointer"
            onClick={() => {
              AnalyticsEvent("header_mobile_refabric_logo", "", "click");
              navigate(PATHS.HOME + window.location.search);
              onClose();
            }}
          />
          <IconClose className="cursor-pointer w-6 h-6 flex-shrink-0" onClick={onClose} />
        </div>
        <div className="flex flex-col">
          {headerItems.map((item: any, index: number) => (
            <div
              key={`MobileHeaderMenuItem_${index}`}
              className={clsx("flex flex-col items-center justify-between p-5", index === 0 && showFeaturesMenu ? "pb-0" : "")}
              onClick={() => {
                item.onClick();
                onClose();
                // if (index === 0) {
                //   setShowFeaturesMenu(!showFeaturesMenu);
                // } else {
                //   item.onClick();
                //   onClose();
                // }
              }}
            >
              <div className="flex justify-between w-full items-center">
                <a className="text-h7-satoshi !text-[20px] !leading-[100%]">{item.title}</a>

                {<IconRightArrow2 className="" />}
                {/* {<IconRightArrow2 className={clsx(index === 0 ? (showFeaturesMenu ? "-rotate-90" : "rotate-90") : "")} />} */}
              </div>

              {showFeaturesMenu && index === 0 && (
                <div className="flex flex-col gap-10 py-5 border-b border-grey-light">
                  <div
                    className="flex gap-5 items-center w-full"
                    onClick={(e) => {
                      e.stopPropagation();

                      AnalyticsEvent("header_features", "", "click");
                      navigate(PATHS.FEATURES + window.location.search);
                      setShowFeaturesMenu(false);
                      onClose();
                    }}
                  >
                    <div className="flex w-full max-w-[90px]">{FeatureItems[6].render && FeatureItems[6].render()}</div>
                    <div className="flex flex-col gap-3">
                      <span className="text-h7-satoshi !text-[20px] text-black">Design Features</span>
                      <span className="text-bodyLg text-black">Visualize and edit your designs on hyper-realistic AI models.</span>
                    </div>
                  </div>
                  <div
                    className="flex gap-5 items-center w-full"
                    onClick={(e) => {
                      e.stopPropagation();

                      AnalyticsEvent("header_ai_photoshoot", "", "click");
                      navigate(PATHS.AI_PHOTOSHOOT + window.location.search);
                      setShowFeaturesMenu(false);
                      onClose();
                    }}
                  >
                    <div className="flex w-full max-w-[90px] rounded-md overflow-hidden aspect-[0.73]">
                      <BeforeAfterImage sliderPercentPosition={40} mainImage={ImageHeader1} images={[ImageHeader2]} />
                    </div>

                    <div className="flex flex-col gap-3">
                      <span className="text-h7-satoshi !text-[20px] text-black">AI Photoshoot</span>
                      <span className="text-bodyLg text-black">Create pro fashion shots without altering garment details.</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="flex flex-col p-5 gap-3">
          <a
            href={APP_URL + "/signup" + window.location.search}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              AnalyticsEvent("header_mobile_signin", "", "click");
            }}
          >
            <Button className="btn-secondary btn-sm whitespace-nowrap w-full">Sign In</Button>
          </a>
          <TryForFree
            btnClassName="btn-primary btn-sm w-full"
            containerClassName="w-full"
            callback={() => {
              AnalyticsEvent("header_mobile_tryforfree", "", "click");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default MobileHeaderMenu;
