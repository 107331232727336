import { MintgramURL, RefabricProURL } from "../index";
import { ApiResponse } from "../HttpClient";
import { IStartPaymentPro } from "./payment.type";

export default {
  async startPaymentBasic(priceId: string): Promise<ApiResponse<any>> {
    return await MintgramURL.get(`v1/start_payment/${priceId}`, {});
  },
  async manageSubscription(): Promise<any> {
    return await MintgramURL.post("v1/payment/manage_portal", {}, {});
  },
  async promote(token: string): Promise<any> {
    return await MintgramURL.get(`v1/use_promo/${token}`, {});
  },

  async startPaymentPro({ product_id, price_id, seat }: IStartPaymentPro): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`/payments/start/${product_id}/${price_id}`, { params: { seat: seat } });
  },
  async getPackageList(): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`/payments/package_list`, {});
  },
  async getPackageListPhotoshoot(): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`/payments/package_list_photoshoot`, {});
  },
};
