import React from "react";
import Button from "components/Button";
import LandingFeatures1 from "images/landing-features-1.png";
import LandingFeatures2 from "images/landing-features-2.png";
import "react-before-after-slider-component/dist/build.css";
import {
  ImageDesignTools1,
  ImageDesignTools2,
  ImageDesignTools_Attribute1,
  ImageDesignTools_Attribute2,
  ImageDesignTools_Color1,
  ImageDesignTools_Color2,
  ImageDesignTools_Color3,
  ImageDesignTools_Color4,
  ImageDesignTools_Color5,
  ImageDesignTools_Color_Select1,
  ImageDesignTools_Color_Select2,
  ImageDesignTools_Color_Select3,
  ImageDesignTools_Color_Select4,
  ImageDesignTools_Fabric1,
  ImageDesignTools_Fabric2,
  ImageDesignTools_Fabric3,
  ImageDesignTools_Fabric4,
  ImageDesignTools_Fabric_Select1,
  ImageDesignTools_Fabric_Select2,
  ImageDesignTools_Fabric_Select3,
  ImageDesignTools_Pattern1,
  ImageDesignTools_Pattern2,
  ImageDesignTools_Pattern3,
  ImageDesignTools_Pattern4,
  ImageDesignTools_Pattern5,
  ImageDesignTools_Pattern6,
  ImageDesignTools_Pattern7,
  ImageDesignTools_Pattern_Select1,
  ImageDesignTools_Pattern_Select2,
  ImageDesignTools_Pattern_Select3,
  ImageDesignTools_Pattern_Select4,
  ImageDesignTools_Photoshoot1,
  ImageDesignTools_Photoshoot2,
  ImageDesignTools_Sketch1,
  ImageDesignTools_Sketch2,
  ImageDesignTools_Technical1,
  ImageDesignTools_Technical2,
  ImageDesignTools_Variations1,
  ImageDesignTools_Variations2,
  ImageDesignTools_Variations3,
  ImageDesignTools_Variations4,
} from "images";
import BeforeAfterImage from "./BeforeAfterImage";
import LazyImg from "components/LazyImg";
import { APP_URL } from "utils/constants";
import { AnalyticsEvent } from "utils";
import ContactUsModal from "components/ContactUsModal";

export const FeatureItems = [
  {
    image: LandingFeatures1,
    title: "Train Brand Identity",
    description: "Capture the unique stylistic DNA and aesthetic diversity across house codes into tailored AI through branded imagery and seasonal mood data.",
  },
  {
    image: LandingFeatures2,
    title: "Design Collections",
    description: "Keep expanding your catalog by conceptualizing seasonal collections customized in your brand's one-of-a-kind stylistic fingerprint powered by AI.",
  },
  {
    title: "Edit Seamlessly",
    description: "Refine specific sections of generated designs by simply selecting areas and providing text or visual guidance.",
    render: () => {
      return (
        <div className="flex w-full max-w-[440px] rounded-md overflow-hidden aspect-[0.73]">
          <BeforeAfterImage mainImage={ImageDesignTools2} images={[ImageDesignTools1]} />
        </div>
      );
    },
  },
  {
    title: "Design and Scale Prints",
    description: "Directly apply prints onto designs for an instant preview of the finished look.",
    render: () => {
      return (
        <div className="flex w-full max-w-[440px] rounded-md overflow-hidden aspect-[0.73]">
          <BeforeAfterImage
            title="prints and graphics library"
            mainImage={ImageDesignTools_Pattern1}
            selectionImages={[ImageDesignTools_Pattern_Select1, ImageDesignTools_Pattern_Select2, ImageDesignTools_Pattern_Select3, ImageDesignTools_Pattern_Select4]}
            images={[ImageDesignTools_Pattern2, ImageDesignTools_Pattern3, ImageDesignTools_Pattern4, ImageDesignTools_Pattern5, ImageDesignTools_Pattern6, ImageDesignTools_Pattern7]}
          />
        </div>
      );
    },
  },
  {
    title: "Fabric Integration",
    description: "Create and design fabric for garments.",
    render: () => {
      return (
        <div className="flex w-full max-w-[440px]  rounded-md overflow-hidden aspect-[0.73]">
          <BeforeAfterImage
            title="Your fabrıc lıbrary"
            sliderPercentPosition={45}
            mainImage={ImageDesignTools_Fabric1}
            selectionImages={[ImageDesignTools_Fabric_Select1, ImageDesignTools_Fabric_Select2, ImageDesignTools_Fabric_Select3]}
            images={[ImageDesignTools_Fabric2, ImageDesignTools_Fabric3, ImageDesignTools_Fabric4]}
          />
        </div>
      );
    },
  },
  {
    title: "Color Library",
    description: "Incorporate precise color codes to ensure consistency and accuracy in design palettes.",
    render: () => {
      return (
        <div className="flex w-full max-w-[440px] rounded-md overflow-hidden aspect-[0.73]">
          <BeforeAfterImage
            title="COLOR lıbrary"
            sliderPercentPosition={45}
            mainImage={ImageDesignTools_Color1}
            selectionImages={[ImageDesignTools_Color_Select1, ImageDesignTools_Color_Select2, ImageDesignTools_Color_Select3, ImageDesignTools_Color_Select4]}
            images={[ImageDesignTools_Color2, ImageDesignTools_Color3, ImageDesignTools_Color4, ImageDesignTools_Color5]}
          />
        </div>
      );
    },
  },
  {
    title: "Sketch to Design",
    description: "Transforms hand-drawn, technical-drawn, and sketches into digital designs.",
    render: () => {
      return (
        <div className="flex w-full max-w-[440px] rounded-md overflow-hidden aspect-[0.73]">
          <BeforeAfterImage sliderPercentPosition={40} mainImage={ImageDesignTools_Sketch1} images={[ImageDesignTools_Sketch2]} />
        </div>
      );
    },
  },
  {
    title: "Create Design Variations",
    description: "Effortlessly create and refine diverse design options, adapting them to your unique style.",
    render: () => {
      return (
        <div className="grid grid-cols-2 gap-3 w-full max-w-[440px] max-h-[600px] p-[18px] bg-bg-3 rounded-md aspect-[0.73]">
          <LazyImg src={ImageDesignTools_Variations1} className="max-h-[276px] rounded-[3px]" />
          <LazyImg src={ImageDesignTools_Variations2} className="max-h-[276px] rounded-[3px]" />
          <LazyImg src={ImageDesignTools_Variations3} className="max-h-[276px] rounded-[3px]" />
          <LazyImg src={ImageDesignTools_Variations4} className="max-h-[276px] rounded-[3px]" />
        </div>
      );
    },
  },
  {
    title: "Attribute Integration",
    description: "Seamlessly combine sketch elements from an extensive library to perfect your design.",
    render: () => {
      return (
        <div className="flex w-full max-w-[440px] rounded-md overflow-hidden aspect-[0.73]">
          <BeforeAfterImage mainImage={ImageDesignTools_Attribute1} images={[ImageDesignTools_Attribute2]} />
        </div>
      );
    },
  },
  // {
  //   title: "Photoshoot",
  //   description: "Display clothing on a diverse model portfolio that embodies your brand's style.",
  //   render: () => {
  //     return (
  //       <div className="flex w-full max-w-[440px] rounded-md overflow-hidden aspect-[0.73]">
  //         <BeforeAfterImage mainImage={ImageDesignTools_Photoshoot1} images={[ImageDesignTools_Photoshoot2]} />
  //       </div>
  //     );
  //   },
  // },
  {
    title: "Technical Packs",
    description: "Converts AI-generated designs into detailed technical packs for bulk production, complete with precise measurements.",
    render: () => {
      return (
        <div className="flex w-full max-w-[440px] rounded-md overflow-hidden aspect-[0.73]">
          <BeforeAfterImage mainImage={ImageDesignTools_Technical1} images={[ImageDesignTools_Technical2]} />
        </div>
      );
    },
  },
];

export const DesignToolsItem = ({ data, eventName, setShowContactUsModal }: any) => {
  const { image, render, title, description } = data;

  return (
    <div className="flex flex-col gap-5 lg:gap-[25px] max-w-[440px]">
      <div className="">{image ? <LazyImg src={image} className="object-contain w-[440px] aspect-[0.73]" /> : render ? render() : <></>}</div>
      <span className="flex items-center gap-2.5 text-h6-satoshi">
        {title}
        {title === "Technical Packs" ? <span className="bg-bg text-headLine text-white py-[2px] px-[5px] rounded-md">coming soon</span> : <></>}
      </span>
      <span className="text">{description}</span>
      {title === "Technical Packs" ? (
        <Button
          className="btn-cta w-fit mt-auto"
          onClick={() => {
            setShowContactUsModal(true);
          }}
        >
          Join Waitlist
        </Button>
      ) : (
        <a
          className="mt-auto"
          href={APP_URL + "/signup" + window.location.search}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            AnalyticsEvent(eventName, "", "click");
          }}
        >
          <Button className="btn-cta w-fit mt-auto">Get Started</Button>
        </a>
      )}
    </div>
  );
};

const DesignTools = () => {
  const [showContactUsModal, setShowContactUsModal] = React.useState(false);

  return (
    <div className="flex flex-col gap-7 lg:gap-[60px] px-[15px] lg:px-10 py-10 lg:py-[100px] border-b border-grey-light">
      {showContactUsModal && (
        <ContactUsModal
          isWaitList={true}
          show={true}
          onClose={() => {
            setShowContactUsModal(false);
          }}
          type="technicalpacks_waitlist"
        />
      )}
      <div className="flex justify-between items-center">
        <span className="text-h5-satoshi lg:text-h3-satoshi">
          <span className="text-orange">Powerful</span> design tools
        </span>
      </div>
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-x-5 lg:gap-y-[143px] gap-y-7">
        {FeatureItems.map((item, index) => (
          <DesignToolsItem key={`DesignToolsItem_${index}`} data={item} eventName={`features_getstarted_${item.title ?? ""}`} setShowContactUsModal={setShowContactUsModal} />
        ))}
      </div>
    </div>
  );
};

export default DesignTools;
