import React, { useEffect, useRef, useState } from "react";
import Button from "components/Button";
import TableComponent from "./TableComponent";
import paymentService from "api/payment/payment.service";
import clsx from "clsx";
import ContactUsModal from "components/ContactUsModal";
import ToggleButton from "components/ToggleButton";
import { AnalyticsEvent } from "utils";
import { IconArrow, IconChecked, IconMinus } from "icons";
import { useScroll } from "components/ScrollProvider/ScrollProvider";
import { useIsMobile } from "hooks/useIsMobile";
import { Swiper, SwiperClass, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import { Pagination } from "swiper/modules";
import { nanoid } from "@reduxjs/toolkit";
import { APP_URL } from "utils/constants";

const colors = {
  bg: ["!bg-pink", "!bg-orange", "!bg-purple", "!bg-green"],
  text: ["!text-pink", "!text-orange", "!text-purple", "!text-green"],
};

const getBackgroundColor = (index: number) => {
  return colors.bg[index] || "";
};

const getTextColor = (index: number) => {
  return colors.text[index] || "";
};

const ListItem = ({ name, value, iconColor }: { name: string; value: any; iconColor: string }) => (
  <li className="flex items-center gap-[5px]">
    {value === "False" ? (
      <>
        <IconMinus className="flex-shrink-0 text-grey w-5 h-5" />
        <span className="text-h6 text-grey">{name}</span>
      </>
    ) : (
      <>
        <IconChecked className={clsx("flex-shrink-0 w-5 h-5", iconColor)} />
        <span className="text-h6 text-black">{value}</span>
      </>
    )}
  </li>
);

export const PriceCard = ({
  index,
  isCurrentPlan,
  data,
  packageDetail,
  handleChoosePlan,
  isYearly,
}: {
  handleChoosePlan: (productId: any, priceId: any) => void;
  packageDetail: any;
  isCurrentPlan?: boolean;
  index: number;
  data: any;
  isYearly: boolean;
}) => {
  const { name, description, price, info, price_id, product_id, button_title } = packageDetail;

  const isHighlighted = index === 1 || index === 2;
  const isPro = index === 3;

  const PriceProvider = () => {
    return (
      <div className="flex flex-col gap-2.5">
        <span className="text-h1">${price}</span>
        <PeriodProvider />
      </div>
    );
  };

  const PeriodProvider = () => {
    const periodTexts = [
      `/month (billed ${isYearly ? "yearly" : "monthly"})`,
      `/month (billed ${isYearly ? "yearly" : "monthly"})`,
      `/month (billed ${isYearly ? "yearly" : "monthly"})`,
      `/per seat/month (billed ${isYearly ? "yearly" : "monthly"})`,
      `/month (billed ${isYearly ? "yearly" : "monthly"})`, // default case
    ];

    const periodText = periodTexts[index] || periodTexts[4];

    return <h5 className="text-bodyMd">{periodText}</h5>;
  };

  return (
    <div className="relative flex flex-col justify-between  w-full px-4 pt-4 pb-5 gap-10 rounded-xl border border-grey-light">
      {isHighlighted && (
        <div className="absolute right-4 flex-center text-headline w-fit px-[7px] py-[3px] bg-black uppercase rounded-[3px] text-white">{index === 1 ? 3 : index === 2 ? 5 : ""}-days free trial </div>
      )}
      {isPro && <div className="absolute right-4 flex-center text-headline w-fit px-[7px] py-[3px] bg-black uppercase rounded-[3px] text-white">pro</div>}

      <PriceProvider />

      <div className="flex flex-col gap-2">
        <span className="text-h7-satoshi">{name}</span>
        <span className="text-bodyMd">{description}</span>
      </div>

      <div className="flex flex-col gap-5">
        <ul className="flex flex-col gap-5">
          {info
            ? info.map((item: any, idx: number) => <ListItem key={"ListItem_" + idx} name={item.name} value={item.value} iconColor={getTextColor(index)} />)
            : data?.monthly[index].info.map((item: any, idx: number) => <ListItem key={"ListItem_" + idx} name={item.name} value={item.value} iconColor={getTextColor(index)} />)}
        </ul>
      </div>

      <div className="flex w-full mt-auto">
        {isCurrentPlan ? (
          <Button className="btn-primary w-full" disabled>
            Current Plan
          </Button>
        ) : (
          <Button className={`btn-primary w-full ${getBackgroundColor(index)}`} onClick={() => handleChoosePlan(product_id, price_id)}>
            {button_title}
          </Button>
        )}
      </div>
    </div>
  );
};

const INITIAL_SLIDE = 1;

const PricingPro = () => {
  const tablesContainerRef = useRef<HTMLDivElement>(null);
  const [showContactUsModal, setShowContactUsModal] = React.useState(false);
  const [isOn, setIsOn] = useState(true);
  const [data, setData] = useState<any>(null);
  const [currentData, setCurrentData] = useState<any>(null);
  const isMobile = useIsMobile();
  const [activeIndex, setActiveIndex] = React.useState(INITIAL_SLIDE);

  const { registerRef } = useScroll();
  const ref = React.useRef<any>(null);
  React.useEffect(() => {
    registerRef("Pricing", ref.current);
  }, [registerRef]);

  useEffect(() => {
    paymentService
      .getPackageList()
      .then((res: any) => {
        setCurrentData(isOn ? res.data.package_list.yearly : res.data.package_list.monthly);
        setData(res.data.package_list);
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    if (data !== null)
      if (isOn) setCurrentData(data.yearly);
      else setCurrentData(data.monthly);
  }, [isOn]);

  function handleToggle() {
    if (isOn) AnalyticsEvent("pricing_monthly_selected", "", "click");
    else AnalyticsEvent("pricing_yearly_selected", "", "click");

    setIsOn((prev) => !prev);
  }

  function handleChoosePlan(productId: string, priceId: string) {
    AnalyticsEvent(`pricing_choose_plan_${productId}`, "", "click");

    window.location.href = `${APP_URL}/signup/${productId}/${priceId}${window.location.search}`;
  }

  const SwiperGoPrevButton = () => {
    const swiper = useSwiper();
    const isDisabled = activeIndex === 0;

    function goBack() {
      if (!isDisabled) swiper.slidePrev();
    }

    return <IconArrow className={clsx("cursor-pointer rotate-180", isDisabled ? "pointer-events-none text-grey" : "text-black")} onClick={goBack} />;
  };

  const SwiperGoNextButton = () => {
    const swiper = useSwiper();
    const isDisabled = activeIndex === currentData.length - 1;

    function goNext() {
      if (!isDisabled) swiper.slideNext();
    }

    return <IconArrow className={clsx("cursor-pointer", isDisabled ? "pointer-events-none text-grey" : "text-black")} onClick={goNext} />;
  };

  return (
    <div className="flex justify-center h-full w-full px-[15px] lg:px-[60px]" id={"Pricing"} ref={ref}>
      {showContactUsModal && (
        <ContactUsModal
          show={showContactUsModal}
          onClose={() => {
            AnalyticsEvent("pricing_enterprise_contact_us_close", "", "click");
            setShowContactUsModal(false);
          }}
          type="enterprise"
        />
      )}
      <div className="flex flex-col h-full w-full gap-8 lg:gap-10 items-center">
        <div className="flex items-center gap-[15px]">
          <h6 className={`text-h6 ${!isOn ? "text-black" : "text-grey"}`}>Monthly</h6>
          <ToggleButton isOn={isOn} onToggle={handleToggle} />
          <h6 className={`text-h6 ${isOn ? "text-black" : "text-grey"}`}>Yearly</h6>
          <span className="font-satoshi text-[13px] font-bold uppercase text-purple leading-[140%] tracking-[0.65px]">20% discount</span>
        </div>

        {/* <div className="flex flex-col w-full gap-5">
          <div className="flex w-full">
            <PricingSlider
              min={1}
              max={11}
              step={1}
              initialValue={1}
              onChange={function (value: number): void {
                setSeatCount(value);
              }}
              marks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]}
              markClassName="plan-mark"
              thumbClassName="plan-thumb"
              trackClassName="plan-track"
            />
          </div>
          <span className="w-full text-center text-black text-headline uppercase">
            {seatCount > 10 ? "10+" : seatCount} seat{seatCount > 1 ? "s" : ""}
          </span>
        </div> */}

        <div className="w-full" id={"Pricing"} ref={ref}>
          {isMobile ? (
            <Swiper
              slidesPerView={"auto"}
              centeredSlides={true}
              spaceBetween={15}
              pagination={{
                clickable: true,
              }}
              onSlideChange={(swiper: SwiperClass) => {
                if (swiper.activeIndex > swiper.previousIndex) AnalyticsEvent("pricing_mobile_swiper_swipe_next", "", "click");
                else AnalyticsEvent("pricing_mobile_swiper_swipe_previous", "", "click");
                setActiveIndex(swiper.activeIndex);
              }}
              modules={[Pagination]}
              initialSlide={INITIAL_SLIDE}
            >
              {currentData?.map((item: any, index: number) => {
                return (
                  <SwiperSlide key={nanoid()}>
                    <div className="flex flex-1 items-center">
                      <SwiperGoPrevButton />
                      <PriceCard
                        key={index}
                        isYearly={isOn}
                        packageDetail={item}
                        data={data}
                        index={index}
                        handleChoosePlan={() => {
                          handleChoosePlan(item.product_id, item.price_id);
                        }}
                      />
                      <SwiperGoNextButton />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : (
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-5 w-full flex-col lg:flex-row">
              {currentData?.map((item: any, index: number) => {
                return (
                  <PriceCard
                    key={index}
                    isYearly={isOn}
                    packageDetail={item}
                    data={data}
                    index={index}
                    handleChoosePlan={() => {
                      handleChoosePlan(item.product_id, item.price_id);
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>

        <div className="flex flex-col gap-5 lg:flex-row w-full p-6 justify-between rounded-lg border border-grey-light">
          <div className="flex flex-col gap-2">
            <h3 className="text-h7-satoshi text-black">Enterprise</h3>
            <span className="text-bodyMd text-grey">Personalized service for your business</span>
          </div>

          <div>
            <ul className="flex flex-col gap-[14px]">
              <li>
                <span className="flex items-center gap-2.5 text-black text-h6 !font-normal">
                  <IconChecked className="w-5 h-5" />
                  <b className="!font-semibold">Custom</b> design credits
                </span>
              </li>
              <li>
                <span className="flex items-center gap-2.5 text-black text-h6 !font-normal">
                  <IconChecked className="w-5 h-5" />
                  <b className="!font-semibold">Unlimited</b> team members
                </span>
              </li>
              <li>
                <span className="flex items-center gap-2.5 text-black text-h6 !font-normal">
                  <IconChecked className="w-5 h-5" />
                  <b className="!font-semibold">Custom</b> monthly train credits
                </span>
              </li>
            </ul>
          </div>

          <div className="flex flex-col gap-5 justify-between">
            <div className="flex flex-col gap-[5px]">
              <h2 className="text-h7-satoshi text-black">Custom</h2>
              <h5 className="text-bodyMd text-black">Looking for a custom solution? Let’s talk!</h5>
            </div>
            <Button
              className="btn-primary text-white !bg-black"
              onClick={() => {
                AnalyticsEvent("pricing_enterprise_contact_us", "", "click");

                setShowContactUsModal(true);
              }}
            >
              Contact Us
            </Button>
          </div>
        </div>

        {data !== null && (
          <div className="flex flex-col w-full lg:w-auto overflow-x-scroll lg:overflow-x-auto" id="table-container" ref={tablesContainerRef}>
            <span className="text-h5-satoshi text-left lg:text-center pb-[50px]">Compare Plans</span>
            <div className="flex flex-col min-w-[800px] lg:w-auto overflow-x-scroll lg:overflow-x-auto">
              <div className="w-full">
                <table className="w-full text-black border-separate border-spacing-4">
                  <tbody>
                    <tr key="header" className="">
                      <td className="text-bodyMd block py-2.5 whitespace-nowrap opacity-0">Download Resolution</td>
                      {data?.monthly.map((item: any, idx: number) => (
                        <td key={idx} className={clsx("text-h5 text-center w-[264px] p-2.5 text-black bg-[#F5F4F3] rounded-lg")}>
                          {item.name}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex w-full bg-[#F5F4F3] text-h6 text-black rounded-full px-[15px] py-3">Usage</div>
              <TableComponent data={data?.monthly} objectKey="usage" headers={data?.monthly[0]?.usage} />
              <div className="flex w-full bg-[#F5F4F3] text-h6 text-black rounded-full px-[15px] py-3">Fashion Style</div>
              <TableComponent data={data?.monthly} objectKey="fashion_style" headers={data?.monthly[0]?.fashion_style} />
              <div className="flex w-full bg-[#F5F4F3] text-h6 text-black rounded-full px-[15px] py-3">Support</div>
              <TableComponent data={data?.monthly} objectKey="support" headers={data?.monthly[0]?.support} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PricingPro;
