import React from "react";

interface Props {
  onToggle: () => void;
  isOn?: boolean;
}

const ToggleButton = ({ onToggle, isOn }: Props) => {
  const [isChecked, setIsChecked] = React.useState(isOn);

  const handleClick = () => {
    setIsChecked((prev) => !prev);
    onToggle();
  };

  return (
    <button className={`relative w-12 bg-grey-light border border-border h-4 rounded-[9px] cursor-pointer`} onClick={handleClick}>
      <span className={`absolute w-7 h-7 bg-black transition-all top-0 bottom-0 left-0 m-auto rounded-full ${isChecked ? "translate-x-[20px]" : ""}`}></span>
    </button>
  );
};

export default ToggleButton;
