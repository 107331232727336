import clsx from "clsx";
import { useScroll } from "components/ScrollProvider/ScrollProvider";
import { IconChevron } from "icons";
import React, { useState } from "react";
import { AnalyticsEvent } from "utils";
import { APP_URL } from "utils/constants";

const questions = [
  {
    id: 1,
    question: "Who are we?",
    description:
      "Refabric specializes in offering generative AI tools that are tailored to meet the needs of fashion designers and brands.  Refabric provides brands and designers the opportunity to curate their own fashion libraries, gain insights into trending styles, capitalize on data from best-selling styles, and utilize AI-assisted design features to create exclusive collections.  For those just starting their creative journey or looking for a platform to explore fashion designs, Refabric has you covered. This user-friendly platform is trained in fashion design, allowing users to create, explore, and even shop for designs that mirror their own creative ideas.  Additionally, we are expanding our offerings to include e-commerce and content marketing solutions to further assist brands and designers in acheiving their business goals.",
  },
  {
    id: 2,
    question: "How to use Refabric's AI Fashion Design?",
    description:
      "Refabric is an AI Fashion Design Assistant that empowers designers to efficiently ideate countless on-trend design options, meeting customer preferences within tight launch timeframes. Using Refabric's range of features, designers can enter prompts to visualize their design creations or use sketches, images, and patterns to draw inspiration for diverse designs. For more advanced design solutions, designers can also use branded imagery and seasonal mood data to train distinct brand identity and moods.",
  },
  {
    id: 3,
    question: "Do I need prior AI experience or design training to use Refabric?",
    description:
      "No, you don't necessarily need prior AI experience or formal design training to use Refabric. Refabric is designed to be user-friendly and accessible to individuals with varying levels of expertise in both AI and fashion design.",
  },
  {
    id: 4,
    question: "Who is Refabric for?",
    description:
      "Refabric can be utilized by anyone in the fashion industry, from individual designers and design studios to textile companies and fashion brands, looking to integrate AI into their fashion design processes.",
  },
  {
    id: 5,
    question: "What is Prompt Assistant?",
    description:
      "Prompt Assistant provides AI support to users by helping them effectively craft and optimize their design prompts. Within Refabric's Design portal, simply select the icon asking if you need help on prompting. This will begin a conversation with Prompt Assistant, enabling the option to either upload a image for Prompt Assistant to describe as a prompt or receive help in optimizing a written prompt.",
  },
  {
    id: 6,
    question: "Is the Refabric's AI Fashion Design Generator suitable for B2B use?",
    description:
      "Refabric can be utilized by anyone - whether you're a fashion professional or style enthusiast. Refabric can be perfectly applied for B2B use as well. Refabric is tailored for more established entities in the fashion industry, offering specialised features and extensive support. It enables training on distinct brand identiy and mood, facilitating the creation of unique designs and customized collections in a brand’s one-of-a-kind stylistic fingerprint powered by AI. ",
  },
  {
    id: 7,
    question: "How do I create an AI image for my clothing brand?",
    render: () => (
      <span>
        You can click on{" "}
        <a className="text-purple underline" href={APP_URL + "/signup" + window.location.search} target="_blank" rel="noopener noreferrer">
          Try Now
        </a>{" "}
        and start your design journey!
      </span>
    ),
  },
  {
    id: 8,
    question: "What is the payment policy for Refabric's AI?",
    render: (scrollToTarget?: any) => (
      <span
        onClick={(e) => {
          e.stopPropagation();
          scrollToTarget("Pricing");
        }}
      >
        You can check the <span className="text-purple underline">Pricing Page</span> for more information. Our prices are customer friendly and optimal for use.
      </span>
    ),
  },
  {
    id: 9,
    question: "Why would I use Refabric's AI Fashion Design Generator?",
    description:
      "Refabric's AI Fashion Design Generator offers several benefits that can be attractive to designers and fashion enthusiasts: Generating fashion designs manually can be time-consuming. Refabric's AI can rapidly produce numerous design concepts, saving time and allowing designers to focus on refining the most promising ideas. The AI can generate a wide range of styles, from classic to avant-garde, catering to diverse tastes and preferences. This variety can help designers explore different aesthetics and expand their design collection. Users can input specific parameters or preferences, such as color schemes, fabric types, or design themes, allowing the AI to tailor the generated designs to their requirements.",
  },
  {
    id: 10,
    question: "What sets Refabric apart from other AI design tools?",
    description:
      "Refabric is specifically tailored for fashion design, offering features and functionalities that are optimized for creating clothing and accessory designs. This specialization allows it to provide more tailored and relevant design solutions for fashion designers and brands. Refabric incorporates trend analysis and forecasting capabilities, helping designers stay ahead of the curve by identifying emerging trends and consumer preferences. This integration of trend analysis enhances the relevance and marketability of the generated designs. ",
  },
  {
    id: 11,
    question: "How does Refabric's AI ensure the sustainability?",
    description:
      "By generating designs digitally, designers can experiment with various styles and iterations without physically producing multiple prototypes. This minimizes material waste associated with traditional design processes.",
  },
];

const FAQ = () => {
  const { scrollToTarget } = useScroll();

  return (
    <div className="flex flex-col items-center gap-[60px] w-[720px]">
      <div className="flex flex-col items-center gap-5">
        <span className="text-h5-satoshi text-center lg:text-start">Frequently Asked Questions</span>
        <span className="text-bodyLg text-center lg:text-start">Can’t find the answer you’re looking for? Please contact our friendly team.</span>
      </div>
      <div className="flex flex-col w-full gap-3 pr-3">
        {questions.map((item) => {
          const [show, setShow] = useState(false);

          return (
            <div
              key={`FAQ_question_${item.id}`}
              className="cursor-pointer flex justify-between w-full p-3 bg-[#F5F4F3] rounded-lg"
              onClick={() => {
                AnalyticsEvent(`pricing_faq_question_${item.id}`, "Click", item.question);
                setShow((prev) => !prev);
              }}
            >
              <div className="flex flex-col w-full gap-3">
                <div className="flex justify-between w-full">
                  <span className="text-bodyLg">{item.question}</span>
                  <IconChevron className={clsx("w-5 h-5transition-all duration-200", show ? "" : "rotate-180")} />
                </div>
                {show ? item.render ? item.render(scrollToTarget) : <div className="text-bodyMd">{item.description}</div> : <></>}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FAQ;
