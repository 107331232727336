import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import Router from "./router/Router";
import store from "./store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { HelmetProvider } from "react-helmet-async";
import "react-multi-carousel/lib/styles.css";

ReactDOM.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_API_KEY as string}>
    <React.StrictMode>
      <Provider store={store}>
        <HelmetProvider>
          <Router />
        </HelmetProvider>
      </Provider>
    </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);
